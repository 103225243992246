import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Crousel from "../../Tools/Crousel";
import Button from "../../Tools/Button";
import { useEffect } from "react";
import { camelToNormal } from "../../../utils/constants";
import { Icon } from "@iconify/react";
import checkMyCarLocale from "../../../locales/checkMyCar.json";

import { Translate } from "react-auto-translate";

export default function SingleCarCheck({ step, setStep }) {
  const location = useLocation();
  const carData = location.state.data;
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  console.log(carData);
  const navigate = useNavigate();
  const [readMore, setReadMore] = useState(false);
  const [basic, setBasic] = useState([]);
  const [features, setFeatures] = useState([]);
  const KEYS = checkMyCarLocale?.[lang];
  const featureValues = ["true", "Std.", true, false];
  const ignoreValues = ["", "N/A", "Opt."];
  const ignoreKeys = ["images", "N/A"];
  useEffect(() => {
    categorizeData();
  }, []);
  const categorizeData = async () => {
    let basicT = [];
    let featuresT = [];
    if (carData) {
      for (const [key, value] of Object.entries(carData)) {
        if (key === "images") continue;
        if (ignoreValues.includes(value)) continue;

        if (featureValues.includes(value)) {
          // featuresT.push({ key: (await googleTranslateFromEnglish(lang, key))?.data.translations[0]?.translatedText, value: value});
          // featuresT.push({ key: key, value: value });
          featuresT.push({ key: key, value: value });
        } else {
          // basicT.push({ key: (await googleTranslateFromEnglish(lang, key))?.data.translations[0]?.translatedText, value: (await googleTranslateFromEnglish(lang, value))?.data.translations[0]?.translatedText });
          // basicT.push({ key: key, value: value });
          basicT.push({ key: key, value: value });
        }
      }
    }
    setBasic(basicT);
    setFeatures(featuresT);
  };

  const getContent = (selected, half) => {
    let basicSliced = [];
    let featuresSliced = [];
    if (half == 1) {
      basicSliced = basic?.slice?.(0, 30);
      featuresSliced = features?.slice?.(0, 30);
    } else if (half == 2) {
      basicSliced = basic?.slice?.(30);
      featuresSliced = features?.slice?.(30);
    } else {
      basicSliced = basic;
      featuresSliced = features;
    }

    switch (selected) {
      case "basic":
        return basicSliced?.map((v) => {
          return (
            <tr>
              <td className="w-50">
                <span>
                  {KEYS?.[v?.key] ? (
                    KEYS?.[v?.key]
                  ) : (
                    <>{camelToNormal(v?.key)}</>
                  )}
                </span>
              </td>
              <td className="w-50">
                <span
                  className={`float-${lang === "en" ? "end" : "start"} text-${
                    lang === "en" ? "end" : "start"
                  }`}
                >
                  {v?.value}
                </span>
              </td>
            </tr>
          );
        });

      case "features":
        return featuresSliced?.map((v) => {
          return (
            <tr>
              <td>
                <span>
                  {KEYS?.[v?.key] ? (
                    KEYS?.[v?.key]
                  ) : (
                    <>{camelToNormal(v?.key)}</>
                  )}
                </span>
              </td>
              <td>
                <span className={`float-${lang === "en" ? "end" : "start"}`}>
                  {/* <Translate>{v?.value}</Translate> */}
                  {v?.value ? (
                    <Icon
                      icon="icon-park-outline:check-one"
                      style={{
                        color: "#3da206",
                        fontSize: "20px",
                        textAlign: "right",
                      }}
                    />
                  ) : (
                    <Icon
                      icon="charm:circle-cross"
                      style={{
                        color: "#e44e4e",
                        fontSize: "20px",
                        textAlign: "right",
                      }}
                    />
                  )}
                </span>
              </td>
            </tr>
          );
        });
    }
  };

  const [showCarousel, setShowCarousel] = useState(true);

  if (!carData?.found) {
    return (
      <div style={{ height: "75vh", marginTop: "180px" }} className="container">
        <div class="comp">
          <h4 className="fs-3" style={{ color: "#3d3d3d" }}>
            {t("Car not found")}
          </h4>
          <p className="fs-5">
            {t("We could not find a car using your provided VIN")}
          </p>
          <p className="fw-semibold text-danger">
            {t("Any deducted amount will be refunded")}
          </p>
          <div class="row">
            <Button
              style="btn"
              onClick={() => {
                navigate("/check_your_cars");
              }}
              title={t("Go back")}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <section class="singlepage">
      <div class="container">
        <h6 class="back">
          <a onClick={() => navigate("/check_your_cars")}>
            <img src="../assets/images/backbt.png" /> {t("Go back")}
          </a>
        </h6>
        <div class="row">
          {showCarousel ? (
            <div class="col-lg-6 col-xxl-7">
              <div class="singlepage_left" style={{ overflow: "hidden" }}>
                <Crousel
                  data={
                    carData?.images && carData?.images?.length > 0
                      ? carData?.images.map(v => ({s3URL: v, thumbnail: v}))
                      : []
                  }
                  showCarousel={showCarousel}
                  setShowCarousel={setShowCarousel}
                />
              </div>
            </div>
          ) : (
            <>
              <h5 className="text-center text-danger rounded-2 p-4 bg-light">
                {t("vin images not found error message")}
              </h5>
            </>
          )}

          <div class="col-lg-6 col-xxl-5">
            <div class="singlepage_right">
              <h1 className="fw-semibold">
                {t(carData?.make) + " " + t(carData?.model)}
              </h1>
              <h1 className="fw-bolder fs-3 mt-3">{carData?.year}</h1>
              <br />

              <div className="row mb-4">
                <div className="col-lg-6 py-3">
                  <span className="px-2">
                    <img
                      src={"../assets/images/mil1.png"}
                      align="icon"
                      height={18}
                    />
                  </span>
                  <span className="fs-6">
                    {KEYS?.[carData?.style] ? (
                      KEYS?.[carData?.style]
                    ) : (
                      <>{carData?.style}</>
                    )}
                  </span>
                </div>
                <div className="col-lg-6 py-3">
                  <span className="px-2">
                    <img
                      src={"../assets/images/mil2.png"}
                      align="icon"
                      height={18}
                    />
                  </span>
                  <span className="fs-6">{t(carData?.engine)}</span>
                </div>
                {/* <div className="col-lg-4 py-3">
                  <span className="px-2">
                    <img
                      src={"../assets/images/mil5.png"}
                      align="icon"
                      height={18}
                    />
                  </span>
                  <span className="fs-6">{t(carData?.fuel)}</span>
                </div> */}
                {/* <div className="col-lg-4 py-3">
                  <span className="px-2">
                    <img
                      src={"../assets/images/cylinders.jpg"}
                      align="icon"
                      height={30}
                    />
                  </span>
                  <span className="fs-6">{t(carData?.cylinders)}</span>
                </div> */}
                {carData?.color && (
                  <div className="col-lg-6 py-3">
                    <span className="px-2">
                      <img
                        src={"../assets/images/car_color.png"}
                        align="icon"
                        height={18}
                      />
                    </span>
                    <span className="fs-6">{t(carData?.color)}</span>
                  </div>
                )}
              </div>

              <p className="fs-3 fw-bold">{t("Dimensions")}</p>
              <div className="row">
                <div className="col-lg-6 py-3 d-flex justify-content-between">
                  <span className="fw-semibold fs-5">{KEYS?.["height"]}</span>
                  <span className=" fs-5">
                    {carData?.height ? carData?.height : "--"}
                  </span>
                </div>
                <div className="col-lg-6 py-3 d-flex justify-content-between">
                  <span className="fw-semibold fs-5">{KEYS?.["width"]}</span>
                  <span className=" fs-5">
                    {carData?.width ? carData?.width : "--"}
                  </span>
                </div>
                <div className="col-lg-6 py-3 d-flex justify-content-between">
                  <span className="fw-semibold fs-5">{KEYS?.["length"]}</span>
                  <span className=" fs-5">
                    {carData?.length ? carData?.length : "--"}
                  </span>
                </div>
                <div className="col-lg-6 py-3 d-flex justify-content-between">
                  <span className="fw-semibold fs-5">
                    {KEYS?.["groundClearance"]}
                  </span>
                  <span className=" fs-5">
                    {carData?.groundClearance ? carData?.groundClearance : "--"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3>{t("Basic Details")}</h3>
            <div
              className="row d-none d-lg-flex"
              // style={{
              //   mb: 6,
              //   overflowY: "scroll",
              //   height: "60vh",
              // }}
            >
              <div className="col-lg-6">
                <table class="table border">
                  <tbody>{getContent("basic", 1)}</tbody>
                </table>
              </div>
              <div className="col-lg-6">
                <table class="table border">
                  <tbody>{getContent("basic", 2)}</tbody>
                </table>
              </div>
            </div>

            <div
              className="d-block d-lg-none"
              style={{
                mb: 6,
                overflowY: "scroll",
                height: "60vh",
              }}
            >
              <table class="table border">
                <tbody>{getContent("basic")}</tbody>
              </table>
            </div>
          </div>

          <div>
            <h3 className="mt-4">{t("Features")}</h3>
            <div
              className="row d-none d-lg-flex"
              // style={{
              //   mb: 6,
              //   overflowY: "scroll",
              //   height: "60vh",
              // }}
            >
              <div className="col-lg-6">
                <table class="table border">
                  <tbody>{getContent("features", 1)}</tbody>
                </table>
              </div>
              <div className="col-lg-6">
                <table class="table border">
                  <tbody>{getContent("features", 2)}</tbody>
                </table>
              </div>
            </div>

            <div
              className="d-block d-lg-none"
              style={{
                mb: 6,
                overflowY: "scroll",
                height: "60vh",
              }}
            >
              <table class="table border">
                <tbody>{getContent("features")}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
