import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { statisData, title } from "../../../../../data/content";
import { vehicleMy } from "../../../../../Reducer/myvehicleSlice";
import MyvehicleTool from "../../../../Tools/Myvehicle";
import TobBar from "./TobBar";
import myvehicle from "../../../../../data/myvechicle.json";
import { useState } from "react";

export default function MyVehicle() {
  const data = useSelector((state) => state.myvehicle.myvehicleArr);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = (props) => {
    const sold = props?.sold;
    const live = props?.live;
    const soonExpire = props?.soonExpire;
    const expired = props?.expired;
    setLoading(true);
    dispatch(
      vehicleMy({
        setLoading,
        sold: sold,
        live: live,
        soonExpire: soonExpire,
        expired: expired,
      })
    );
  };

  myvehicle.data.tobbar[0].count = data?.data?.totalAds
    ? data?.data?.totalAds
    : "0";
  document.title = title.Myvehicles[i18n.language];

  // if (loading) {
  //   return (
  //     <div
  //       class="d-flex justify-content-center"
  //       style={{ minHeight: "100vh" }}
  //     >
  //       <div class="spinner-grow" role="status">
  //         <span class="visually-hidden">Loading...</span>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      <TobBar
        data={myvehicle.data.tobbar ? myvehicle.data.tobbar : []}
        refresh={refresh}
      />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : data?.data?.totalAds ? (
        <div class="row">
          <MyvehicleTool data={data?.data?.ads} refresh={refresh} />
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          {!data?.status && (
            <h5 style={{ fontWeight: "500" }} className="text-center">
              {t("No vehicles available")}
            </h5>
          )}
        </div>
      )}
    </>
  );
}
