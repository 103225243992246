import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStats } from "../../../../../Reducer/api";

export default function TobBar({ refresh }) {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState({});
  useEffect(() => {
    getStats().then((res) => {
      setData(res);
    });
  }, [refresh]);
  console.log(data)
  return (
    // <ul class="livetst " style={{ top: "unset", marginBottom: "15px" }}>
    //   {i18n.language === "English" ? (
    // <li
    //   style={{ color: "#4C7EE8" }}
    //   className={`text-${i18n.language === "English" ? "start" : "end"}`}
    // >
    //   {data.liveAds || data.liveAds == 0 ? data.liveAds : "--"}
    //   <span>{t("Live Adverts")}</span>
    // </li>
    //   ) : (
    //     <li
    //       style={{ color: "#4C7EE8" }}
    //       className={`text-${i18n.language === "English" ? "start" : "end"}`}
    //     >
    //       {data.soldAds || data.soldAds == 0 ? data.soldAds : "--"}
    //       <span>{t("Sold")}</span>
    //     </li>
    //   )}
    // <li
    //   style={{ color: "#4C7EE8" }}
    //   className={`text-${i18n.language === "English" ? "start" : "end"}`}
    // >
    //   {data.expiringSoonAds || data.expiringSoonAds == 0
    //     ? data.expiringSoonAds
    //     : "--"}
    //   <span>{t("Soon to Expire")}</span>
    // </li>
    //   {i18n.language === "English" ? (
    // <li
    //   style={{ color: "#4C7EE8" }}
    //   className={`text-${i18n.language === "English" ? "start" : "end"}`}
    // >
    //   {data.soldAds || data.soldAds == 0 ? data.soldAds : "--"}
    //   <span>{t("Sold")}</span>
    // </li>
    //   ) : (
    //     <li
    //       style={{ color: "#4C7EE8" }}
    //       className={`text-${i18n.language === "English" ? "start" : "end"}`}
    //     >
    //       {data.liveAds || data.liveAds == 0 ? data.liveAds : "--"}
    //       <span>{t("Live Adverts")}</span>
    //     </li>
    //   )}
    // </ul>
    <div
      // style={{ backgroundColor: "#F9F9F9", borderRadius: "8px" }}
      style={{ color: "#4C7EE8" }}
      className="row px-2 stats"
    >
      <div
        className={`col-6 col-lg-3 px-2 px-lg-0 mb-3 text-${
          i18n.language === "English" ? "start" : "end"
        }`}
      >
        <div
          style={{
            borderRadius:
              i18n.language === "English"
                ? "8px 0px 0px 8px"
                : "0px 8px 8px 0px",
          }}
        >
          <div
            style={{
              [`border${i18n.language === "English" ? "Right" : "Left"}`]:
                "1px solid #ccc",
              cursor: "pointer",
            }}
            onClick={() => refresh({ live: true })}
          >
            <span className="fs-4 fw-semibold">
              {data?.liveAds || data?.liveAds == 0 ? data?.liveAds : "--"}
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "15px",
                fontWeight: "400",
                color: "#0C0C0C",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden"
              }}
            >
              {t("Live Adverts")}
            </span>
          </div>
        </div>
      </div>

      <div
        className={`col-6 col-lg-3 px-2 px-lg-0 mb-3 text-${
          i18n.language === "English" ? "start" : "end"
        }`}
      >
        <div>
          <div
            style={{
              [`border${i18n.language === "English" ? "Right" : "Left"}`]:
                "1px solid #ccc",
              cursor: "pointer",
            }}
            onClick={() => refresh({ soonExpire: true})}
          >
            <span className="fs-4 fw-semibold">
              {data?.expiringSoonAds || data?.expiringSoonAds == 0
                ? data?.expiringSoonAds
                : "--"}
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "15px",
                fontWeight: "400",
                color: "#0C0C0C",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden"
              }}
            >
              {t("Soon to Expire")}
            </span>
          </div>
        </div>
      </div>

      <div
        className={`col-6 col-lg-3 px-2 px-lg-0 mb-3 text-${
          i18n.language === "English" ? "start" : "end"
        }`}
      >
        <div>
          <div
            style={{
              [`border${i18n.language === "English" ? "Right" : "Left"}`]:
                "1px solid #ccc",
              cursor: "pointer",
            }}
            onClick={() => refresh({ expired: true})}
          >
            <span className="fs-4 fw-semibold">
              {data?.expiredAds || data?.expiredAds == 0
                ? data?.expiredAds
                : "--"}
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "15px",
                fontWeight: "400",
                color: "#0C0C0C",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden"
              }}
            >
              {t("Expired")}
            </span>
          </div>
        </div>
      </div>

      <div
        className={`col-6 col-lg-3 px-2 px-lg-0 mb-3 text-${
          i18n.language === "English" ? "start" : "end"
        }`}
      >
        <div
          style={{
            borderRadius:
              i18n.language === "English"
                ? "0px 8px 8px 0px"
                : "8px 0px 0px 8px",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => refresh({ sold: true })}
          >
            <span className="fs-4 fw-semibold">
              {data?.soldAds || data?.soldAds == 0 ? data?.soldAds : "--"}
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "15px",
                fontWeight: "400",
                color: "#0C0C0C",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden"
              }}
            >
              {t("Sold")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
