import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { saveCarForm } from "../../../Reducer/saveCarSlice";
import { fetchUploadFormDetails } from "../../../Reducer/uploadFormSlice";
// import Media from '../../Tools/SRPages/FirstStep/Media';
import Media from "../../Tools/Media";
import { errorSms } from "../../../data/content";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { apiManager } from "../../../api/connectAPI";
import ListItem from "../../Tools/ListItem";

//Iconify
import { Icon } from "@iconify/react";

export default function ({
  back,
  data,
  setPage,
  setData,
  page,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const submitting = useSelector((state) => state.saveCar.submitting);
  const error = useSelector((state) => state.saveCar.error);
  const success = useSelector((state) => state.saveCar.success);
  const [submit, setSubmit] = useState(false);
  const [uploadsInOrder, setUploadsInOrder] = useState([]);
  const [inputFile, setInputFile] = useState([]);
  const [InputFileError, setInputFileError] = useState(t("File is required"));
  const [uploading, setUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [imageList, setImageList] = useState([])
  const schema = "";
  //   const [formData, setFormData] = useState();

  const editing = location?.state?.editing;
  const userId = location?.state?.userId || JSON.parse(localStorage.getItem('user')).id
  const myProfile = JSON.parse(localStorage.getItem("myProfile"));

  const additionalInfo = myProfile?.additionalInfo
    ? myProfile?.additionalInfo
    : {};
  let move = success && !submitting;


  // if (move) {
  //   navigate("/");
  // }

  useEffect(() => {
    dispatch(fetchUploadFormDetails());
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onError = (errors) => {
    console.log(errors);
  };

  //   const onSubmit = async (data) => {
  //     console.log(data);

  //     setSubmit(true);
  // if (submitting == false && base64.length > 0) {
  //   setInputFileError("");
  //   let formData = new FormData();
  //   for await (const num of inputFile) {
  //     formData.append("images[]", num);
  //   }

  //   console.log(formData);
  //   try{
  //      dispatch(saveCarForm(formData));
  //   }catch(e) {
  //      toast.error(e.message);
  //   }
  // }
  //   };

  const handleContinue = (e) => {
    e.preventDefault();

    if (uploadsInOrder.length === 0) {
      setInputFileError("At least 1 document is required");
      return;
    }

    const data = {
      isKycProvided: true,
      isKycUploaded: 1,
      additionalInfo: {
        ...additionalInfo,
        dealerKycDocuments: uploadsInOrder.map(v => v?.s3URL)
      },
    };

    apiManager("PUT", "user/profile?lang=en", data)
    .then(res1 => {
      if(res1.status) {
        toast.success('Welcome!')
      } else {
        toast.error(res1.message)
      }
    })
    .catch(err => {
      console.error(err)
      toast.error('Something went wrong!')
    })

    if (editing) {
      navigate("/MyAccount/MyProfile");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    setInputFileError("");
  }, [inputFile]);

  const [uploaded, setUploaded] = useState([]);

  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
        break;
      default:
        toast.error(t(errorSms.first));
        break;
    }
  }

  console.log(uploadsInOrder)
  
  const [visibleSection, setVisibleSection] = useState(true);
  return (
    <>
      <section class="login">
        <div class="container">
          <div class="advance_search newt mt-0">
            <h6 class="back text-start mt-0">
              {editing && (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/MyAccount/MyProfile")}
                >
                  <img src="../assets/images/backbt.png" /> {t("Go back")}
                </a>
              )}
            </h6>
          </div>
          {error && (
            <div>
              {t("Error")}: {error}
            </div>
          )}
          <form
            class="form-signin signin2"
            // onSubmit={handleSubmit(onSubmit, onError)}
          >
            <h1>{t("More Information")}</h1>
            <h4>{t("Upload Dealership Document")}</h4>
            {visibleSection ? (
              <Media
                submit={submit}
                setInputFileError={setInputFileError}
                inputFile={inputFile}
                register={register}
                InputFileError={InputFileError}
                uploadsInOrder={uploadsInOrder}
                setUploadsInOrder={setUploadsInOrder}
                setInputFile={setInputFile}
                status={"false"}
                class1={"row uploadimg"}
                class2={"col-4"}
                // handleUploadDocument={handleUploadDocument}
                uploading={uploading}
                setUploading={setUploading}
                callFunc={setVisibleSection}
                listOfImages={imageList}
                setListOfImages={setImageList}
                type={8}
                userId={userId}
              />
            ) : (
              <div className="">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    style={{ cursor: "pointer" }}
                    height={22}
                    onClick={() => setVisibleSection(true)}
                    icon="tabler:edit"
                  />
                </div>
                <div class="card my-1 p-1" style={{ width: "100%" }}>
                  <img
                    src={uploadsInOrder?.[0]?.s3URL}
                    alt=""
                    height={200}
                    class="card-img-top"
                  />
                </div>
              </div>
            )}

            {uploaded.length !== 0 && visibleSection ? (
              <>Documents Uploaded</>
            ) : null}

            {visibleSection && (
              <ul className="row uploadimg">
                {uploaded.map((item, key) => {
                  return (
                    <ListItem
                      key={key}
                      style={"col-4"}
                      innerhtmlType={"html"}
                      value={
                        <div
                          class="st"
                          style={{ backgroundImage: `url(${item})` }}
                        ></div>
                      }
                    ></ListItem>
                  );
                })}
              </ul>
            )}

            <div class="row justify-content-end">
              <div class="mt-5">
                <button
                  class="btn"
                  type="buttton"
                  disabled={uploadsInOrder.length === 0 ? true : false}
                  onClick={handleContinue}
                >
                  {t("Continue")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
